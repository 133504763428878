
import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import {
  Box, Button, Container, Grid, Toolbar,
  List, ListItem, ListItemText, ListItemIcon, Link
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';


interface Props {
}

interface States {
  sheets: Array<gapi.client.sheets.Sheet>
}

export class InventorySheetsList extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sheets: []
    };
  }

  componentDidMount() {
    gapi.client.sheets.spreadsheets.get({
      // 在庫管理シート https://docs.google.com/spreadsheets/d/1Way7iEIIuSgOhetRdUctZxwlO2s61JQuKZB2Hv_5Ckk/edit#gid=0
      spreadsheetId: '1Way7iEIIuSgOhetRdUctZxwlO2s61JQuKZB2Hv_5Ckk',
      //            ranges: 'A:K'
    }).then(response => {
      console.log(response)
      if (!response.result.sheets) {
        console.error('sheets empty')
        return
      }

      const sheets = response.result.sheets.filter(sheet =>
        sheet.properties?.title?.match(/^\d{4}_\d{2}$/)
      )

      console.log(sheets)
      this.setState({
        sheets
      })
    })
  }


  render() {
    return <div>
      <p>
        シートがまだないときは、<a href="https://docs.google.com/spreadsheets/d/1Way7iEIIuSgOhetRdUctZxwlO2s61JQuKZB2Hv_5Ckk/edit#gid=0" target="_blank">在庫管理シート</a>で<code>テンプレート</code>をコピーして作ってください。
      </p>
      <ul>
        <List dense={true}>
          {
            this.state.sheets.map(sheet => {
              const title = sheet.properties?.title
              return <ListItem>
                <ListItemLink
                  to={
                    `/yearmonth/${title}`
                  }
                  icon={<CalendarTodayIcon /> }
                  primary={title}
                />
              </ListItem>
            })
          }
        </List>
      </ul>
    </div>
  }
}

function ListItemLink(props: any) {
  const { icon, primary, to } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps) => <RouterLink to={to} {...itemProps} />),
    [to],
  );

  return (
    <ListItem button component={renderLink}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      <ListItemText primary={primary} />
    </ListItem>
  );
}
