import React from 'react';

declare global {
    interface Window { BarcodeDetector: any; }
}

interface Props {
    detected: (text: string) => void
}

interface States {
    supportsQr: boolean
    errorMessage: string
    sequenceNumber: number
}

interface DetectedBarcode {
    boundingBox: DetectedBarcodeBoundingBox
    rawValue: string
}

interface DetectedBarcodeBoundingBox {
    x: number
    y: number
    width: number
    height: number
}

class CodeScanner extends React.Component<Props, States> {
    detector: any
    video: HTMLVideoElement | null = null

    constructor(props: Props) {
        super(props);
        this.state = {
            supportsQr: false,
            errorMessage: '',
            sequenceNumber: 0,
        };
    }

    onError = (error: Error) => {
        console.log(error)
        this.setState({ errorMessage: error.message })

    }

    componentDidMount() {
        if (!window.BarcodeDetector) {
            this.onError(new Error('BarcodeDetector is not available'))
            return
        }
        if (!window.navigator.mediaDevices) {
            this.onError(new Error('mediaDevices is not available'))
            return
        }

        window.BarcodeDetector.getSupportedFormats().then((formats: string[]) => {
            if (formats.includes('qr_code')) {
                navigator.mediaDevices.getUserMedia(media).then(stream => {
                    let video = document.getElementById('scanner-video') as HTMLVideoElement
                    if (!video) {
                        this.onError(new Error('video element not found'))
                        return
                    }
                    this.video = video

                    video.srcObject = stream;
                    this.detector = new window.BarcodeDetector();
                    this.detect()
                    this.setState({ supportsQr: true })
                }, (error: Error) => {
                    this.onError(new Error(`getUserMedia failed:${error.name}:${error.message}`))
                })
            } else {
                this.onError(new Error('qr_code is not supported'))
            }
        }, (error: Error) => {
            this.onError(new Error('getSupportedFormats failed ' + error.message))
        })

        const media = {
            video: {
                width: document.body.clientWidth,
                height: document.body.clientWidth,
                facingMode: {
                   exact: "environment" // リアカメラを使用
                }
            },
            audio: false
        };
    }

    detect = () => {
        setTimeout(() => {

            if (this.video) {
                this.detector.detect(this.video).then((barcodes: DetectedBarcode[]) => {
                    this.onCodeDetected(barcodes)
                    this.detect()
                }, (error: Error) => {
                    this.onError(new Error('detect failed ' + error.message))
                    this.detect()
                })
                this.setState({ sequenceNumber: this.state.sequenceNumber + 1 })
            }
        }, 30)
    }

    onCodeDetected = (barcodes: DetectedBarcode[]) => {
        for (const barcode of barcodes) {
            const text = barcode.rawValue
            this.setState({ errorMessage: text })
            this.props.detected(text.trim())

            setTimeout( () => {
                window.navigator.vibrate([200]);
            }, 0)
        }
    }

    componentWillUnmount() {
        if (this.video) {
            this.video.srcObject = null
            this.video = null
        }
    }

    onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault()

        const form = (event.target as HTMLFormElement)
        const element = form.elements.namedItem('item_id') as HTMLInputElement
        const value = element?.value

        const id = value.trim()
        this.props.detected(id)
    }

    render() {
        return <div>
            <video id="scanner-video" muted autoPlay style={{ width: '100%' }}></video>
            {
                (this.state.supportsQr) ? <div>
                    <span>{this.state.errorMessage}</span>
                </div> : <div>
                        <span>{this.state.errorMessage}</span>
                        <form onSubmit={(ev) => { this.onSubmit(ev) }} >
                            <input type="text" id="item_id" placeholder="karatz_item_id" />
                            <input type="submit" />
                        </form>
                    </div>
            }
        </div>
    }
}

export { CodeScanner }
