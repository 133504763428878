import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { Box, Button, Container, Grid, Toolbar } from '@material-ui/core';
import './App.css';
import packagejson from "../package.json";
import { builtAt } from './built';
import { GoogleAuth, GoogleAuthContext } from './GoogleAuth'

import { Header } from './Header'
import { InventorySheetsList } from './InventorySheetsList'
import { InventorySheet } from './InventorySheet'
import { render } from '@testing-library/react';
import { Dependencies } from './Dependencies';

const versionSignature = (new Date(builtAt * 1000)).toISOString()


function handleYes() {
  console.log(1)
}

function handleNo() {
  console.log(2)

}

function routes(deps: Dependencies): React.ReactNode {
  return (
    <Box>
      <Header deps={deps} />
      <Toolbar />
      <Container fixed>
        <Grid item xs={12}>
          <Router>
            <Route path="/" exact component={ InventorySheetsList } />
            <Route path="/yearmonth/:yyyy_mm" exact component={ (props: any) => {
              return <InventorySheet {...props} deps={deps} />
          } } />
          </Router>
        </Grid>
      </Container>
    </Box>

  )
}


function App() {
  return (
    <div className="App">
      <GoogleAuth>
        <GoogleAuthContext.Consumer>
          {
            (props: any) => {
              const { profile } = props
              return routes({profile})
            }
          }
        </GoogleAuthContext.Consumer>
      </GoogleAuth>
      <Box style={{ fontSize: 'xx-small', textAlign: "right", margin: 8 }}>
        v{packagejson.version} {versionSignature}
      </Box>
    </div>
  );
}

export default App;
/*
*/

