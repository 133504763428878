
import React from 'react';
import { Link as RouterLink, RouteComponentProps } from "react-router-dom";
import {
  Box, Button, Container, Grid, Toolbar,
  List, ListItem, ListItemText, ListItemIcon, Link
} from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { Typography } from '@material-ui/core';
import { CodeScanner } from './CodeScanner'
import { Dependencies } from './Dependencies';

const formatter = new Intl.NumberFormat('en-us', {}).format

interface Props extends RouteComponentProps<{
  yyyy_mm: string
}> {
  readonly deps: Dependencies
}

class Item {
  stock: number
  verifiedAt: number
  verifiedBy: string
  recaratItemId: string
  expectedStock: number
  name: string
  index: number
  constructor(cols: Array<string>, index: number) {
    this.stock = Number(cols[0] || 0)

    this.verifiedAt =  0 //cols[1]
    this.verifiedBy = cols[2]
    this.recaratItemId = cols[4]
    this.expectedStock = Number(cols[7] || 0)
    this.name = cols[8]
    this.index = index
  }

  toRow(): Array<string|object> {
    return [
      String(this.stock),
      `=${String(this.verifiedAt)} / 86400 + date(1970, 1, 1)`,

    //      formulaValue: `=${String(this.verifiedAt / 1000)} / 86400 + date(1970, 1, 1)`,
    //     userEnteredFormat: {
    //       numberFormat: {
    //         type: "DATE_TIME",
    //         pattern: "yyyy-mm-dd hh:mm",
    //       }
    //     }
    //  },
    //   {
      this.verifiedBy,
    ]
  }
}

interface States {
  numberOfItems: number
  scannedItems: Array<Item>
}

export class InventorySheet extends React.Component<Props, States> {
  itemsMap: Map<string, Item>
  constructor(props: Props) {
    super(props);
    this.state = {
      numberOfItems: 0,
      scannedItems: [],
    };
    this.itemsMap = new Map()
  }

  componentDidMount() {
    gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId: '1Way7iEIIuSgOhetRdUctZxwlO2s61JQuKZB2Hv_5Ckk',
      range: `${ this.props.match.params.yyyy_mm }!A:I`
    }).then( response => {
      this.translate(response)
      this.setState({numberOfItems: this.itemsMap.size})
    }, ( error ) => {
        alert(error.result.error.message)
    })
  }

  translate(response: gapi.client.Response<gapi.client.sheets.ValueRange>) {
    const values = response.result.values
    if (!values) {
      return
    }

    const header = values.shift()

    for (let i = 0; i < values.length; i += 1) {
      const item = new Item(values[i], i)
      this.itemsMap.set(item.recaratItemId, item)
    }
  }

  detected(text: string) {
    const item = this.itemsMap.get(text)
    if (!item) {
      alert(`"${text}"のアイテムがみつかりませんでした。シートにアイテムが存在するか確認してみてください。`)
      return
    }

    let n = 1
    if (item.expectedStock > 1) {

      const input = window.prompt("実在庫数を入力してください")
      n = Number(input || 0)
    }

    if (n < 1) {
      return
    }

    item.stock = n
    item.verifiedAt = Math.floor( Date.now() / 1000 )
    item.verifiedBy = this.props.deps.profile.getEmail()

    let scannedItems: Array<Item>
    const found = this.state.scannedItems.find(item => item.recaratItemId === text)
    if (found) {
      found.stock = item.stock
      scannedItems = this.state.scannedItems
    } else {
      scannedItems = [item].concat(this.state.scannedItems)
    }
    this.setState({
      scannedItems
    })

    this.saveItem(item)

    window.navigator.vibrate(500)

  }

  saveItem(item: Item) {
    const rowNumber = item.index + 1 + 1 // +1 origin=1 +1 skip header
    const params = {
      spreadsheetId: '1Way7iEIIuSgOhetRdUctZxwlO2s61JQuKZB2Hv_5Ckk',
      range: `${this.props.match.params.yyyy_mm}!A${rowNumber}:C${rowNumber}`,
      valueInputOption: 'USER_ENTERED',
      resource: {
        values: [item.toRow() ]
      }
    }
    console.log(params)
    gapi.client.sheets.spreadsheets.values.update(params).then(response => {

    }, (error) => {
        alert(error.result.error.message)
    })
  }

  table(): React.ReactNode | null {
    if (this.state.scannedItems.length <= 0 ) {
      return null
    }

    return (
      <table>
        <thead>
          <tr>
            <td>id</td>
            <td>品名</td>
            <td>在庫(実/期待)</td>
          </tr>
        </thead>
        <tbody>
          {
            this.state.scannedItems.map(item => {
              return (
                <tr key={item.recaratItemId}>
                  <td><small>{item.recaratItemId}</small></td>
                  <td><small>{item.name}</small></td><td>{item.stock}/{item.expectedStock}</td>
                </tr>
              )
            })

          }
        </tbody>
      </table>
    )
  }

  render() {
    return (
      <div>
        <CodeScanner detected={(text) => {
          this.detected(text)
        }} />
        scanned { this.state.scannedItems.length} items
        {this.table() }
        <Typography variant="h5" component="h4">
          {this.props.match.params.yyyy_mm} / {formatter(this.state.numberOfItems)} items
        </Typography>
      </div>
    )
  }
}

