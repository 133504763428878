import React from 'react';
import { Avatar, AppBar, Toolbar,
    MenuList, MenuItem, IconButton,
    Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import { Dependencies } from './Dependencies';

interface Props {
    deps: Dependencies
}

interface State {
    isModalOpen: boolean
}

class Header extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isModalOpen: false
        };
    }

    render() {
        const profile = this.props.deps.profile
        return (
            <AppBar >
                <Toolbar>
                    {
                    // <IconButton edge="start" color="inherit" aria-label="menu">
                    //     <MenuIcon />
                    // </IconButton>
                }
                    <Typography variant="h6" style={{
                        flexGrow: 1
                    }}>KARATZ棚卸しスキャナ</Typography>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                        color="inherit"
                    >
                        <Avatar alt={profile.getName()} src={profile.getImageUrl()} />
                    </IconButton>
                </Toolbar>
                <Dialog
                    open={this.state.isModalOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">ログアウト</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            ほんとうにログアウトしますか?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">いいえ</Button>
                        <Button onClick={() => this.onConfirm()} color="primary" autoFocus>はい</Button>
                    </DialogActions>
                </Dialog>

            </AppBar>

        )
    }

    handleMenu = () => {
        this.setState({isModalOpen: true})
    }
    handleClose = () => {

        this.setState({ isModalOpen: false })
    }
    onConfirm = () => {
        this.setState({ isModalOpen: false })

        window.gapi.auth2.getAuthInstance().signOut().then( () => {
            document.location.reload()
        })
    }
}

export { Header }
